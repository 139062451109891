// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";

function visit(url) {
  window.location.href = url;
}

function visitBlank(url) {
  window.open(url);
}

function formatUrl(url) {
  if (Js_string.startsWith(url, "http://") || Js_string.startsWith(url, "https://")) {
    return url;
  } else {
    return "https://" + url;
  }
}

var Helper = {
  formatUrl: formatUrl
};

export {
  visit ,
  visitBlank ,
  Helper ,
}
/* No side effect */
