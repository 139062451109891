// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function Svg(props) {
  var title = props.title;
  var id = "icon-title-" + title;
  var viewBox = "0 0 " + (props.viewBoxWidth + (" " + props.viewBoxHeight));
  return JsxRuntime.jsxs("svg", {
              children: [
                JsxRuntime.jsx("title", {
                      children: title,
                      id: id
                    }),
                props.children
              ],
              "aria-labelledby": id,
              className: props.className,
              viewBox: viewBox,
              xmlns: "http://www.w3.org/2000/svg"
            });
}

var make = Svg;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
