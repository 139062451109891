// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as SpinnerScss from "./Spinner.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = SpinnerScss;

function px(value) {
  return String(value) + "px";
}

function getColorClassName(color) {
  switch (color) {
    case "Orange" :
        return css.colorOrange;
    case "Teal" :
        return css.colorTeal;
    case "Pink" :
        return css.colorPink;
    case "Red" :
        return css.colorRed;
    case "White" :
        return css.colorWhite;
    case "Black" :
        return css.colorBlack;
    case "RedDarker" :
        return css.colorRedDarker;
    
  }
}

function buildContainerClassName(bg, centered) {
  var bgClassName = bg !== undefined ? (
      bg === "WhiteBg" ? css.bgWhite : css.bgSemiTransparentDark
    ) : "";
  return Cx.cx([
              centered ? css.centeredContainer : css.container,
              bgClassName
            ]);
}

function Spinner(props) {
  var __centered = props.centered;
  var centered = __centered !== undefined ? __centered : true;
  var mappedSize;
  switch (props.size) {
    case "XS" :
        mappedSize = 12;
        break;
    case "SM" :
        mappedSize = 14;
        break;
    case "MD" :
        mappedSize = 18;
        break;
    case "LG" :
        mappedSize = 22;
        break;
    case "XL" :
        mappedSize = 28;
        break;
    
  }
  var normalizedSize = mappedSize * 0.75;
  var spinnerHeight = normalizedSize / 1.5 | 0;
  var colMargin = normalizedSize > 15.0 ? 3 : 2;
  var colWidth = normalizedSize * 0.5 | 0;
  var colHeight = normalizedSize | 0;
  var colorClassName = getColorClassName(props.color);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsx("span", {
                                  className: Cx.cx([
                                        css.colLeft,
                                        colorClassName
                                      ]),
                                  style: {
                                    height: String(colHeight) + "px",
                                    width: String(colWidth) + "px"
                                  }
                                }),
                            JsxRuntime.jsx("span", {
                                  className: Cx.cx([
                                        css.colMiddle,
                                        colorClassName
                                      ]),
                                  style: {
                                    height: String(colHeight) + "px",
                                    marginRight: String(colMargin) + "px",
                                    marginLeft: String(colMargin) + "px",
                                    width: String(colWidth) + "px"
                                  }
                                }),
                            JsxRuntime.jsx("span", {
                                  className: Cx.cx([
                                        css.colRight,
                                        colorClassName
                                      ]),
                                  style: {
                                    height: String(colHeight) + "px",
                                    width: String(colWidth) + "px"
                                  }
                                })
                          ],
                          className: css.spinner,
                          style: {
                            height: String(spinnerHeight) + "px"
                          }
                        }),
                    className: centered ? css.centeredWrapper : css.wrapper
                  }),
              className: buildContainerClassName(props.bg, centered)
            });
}

var make = Spinner;

export {
  css ,
  px ,
  getColorClassName ,
  buildContainerClassName ,
  make ,
}
/* css Not a pure module */
