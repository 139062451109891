// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Svg from "../../../styleguide/components/Svg/Svg.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function SuccessImage(props) {
  return JsxRuntime.jsx(Svg.make, {
              title: "Success",
              viewBoxWidth: "442",
              viewBoxHeight: "140",
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M-3.63797881e-12,70 C21.3333333,46.8151042 50.6666667,35.2226562 88,35.2226562 C154.217717,35.2226562 159.40394,77.4644217 286,92.9414063 C338,99.2986594 378,86.0942323 406,53.328125",
                            stroke: "#7ACCC8",
                            strokeDasharray: "11,18",
                            strokeLinecap: "round",
                            strokeWidth: "3"
                          }),
                      JsxRuntime.jsxs("g", {
                            children: [
                              JsxRuntime.jsx("circle", {
                                    cx: "70",
                                    cy: "70",
                                    fill: "#FFFFFF",
                                    r: "66",
                                    stroke: "#7ACCC8",
                                    strokeWidth: "8"
                                  }),
                              JsxRuntime.jsx("path", {
                                    d: "M49.5355339,68.5355339 L59.4644661,78.4644661 C61.4170876,80.4170876 64.5829124,80.4170876 66.5355339,78.4644661 L91.4644661,53.5355339 C93.4170876,51.5829124 96.5829124,51.5829124 98.5355339,53.5355339 L99.4644661,54.4644661 C101.417088,56.4170876 101.417088,59.5829124 99.4644661,61.5355339 L66.5355339,94.4644661 C64.5829124,96.4170876 61.4170876,96.4170876 59.4644661,94.4644661 L41.5355339,76.5355339 C39.5829124,74.5829124 39.5829124,71.4170876 41.5355339,69.4644661 L42.4644661,68.5355339 C44.4170876,66.5829124 47.5829124,66.5829124 49.5355339,68.5355339 Z",
                                    fill: "#933CDD"
                                  })
                            ],
                            transform: "translate(150.000000, 0.000000)"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M399.510287,35.5305776 L439,19 L410,46 L399.201048,39.0578165 C398.271908,38.4605122 398.002902,37.2230845 398.600206,36.2939444 C398.819259,35.9531955 399.13662,35.6869964 399.510287,35.5305776 Z",
                            fill: "#18C3BB"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M413,49 L440,20 L423.469422,59.4897131 C423.042906,60.5086133 421.871164,60.9888353 420.852264,60.5623189 C420.478597,60.4059001 420.161236,60.139701 419.942183,59.7989521 L413,49 Z",
                            fill: "#18C3BB"
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd",
                    stroke: "none",
                    strokeWidth: "1"
                  })
            });
}

var make = SuccessImage;

export {
  make ,
}
/* Svg Not a pure module */
