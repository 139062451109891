// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../styleguide/components/Heading/H2.res.js";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as Container from "../../styleguide/components/Container/Container.res.js";
import * as SuccessImage from "../product-reservation/confirm/SuccessImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LeadConfirmedScss from "./LeadConfirmed.scss";

var css = LeadConfirmedScss;

var goBack = (function(){window.history.back();});

function LeadConfirmed(props) {
  var tmp;
  switch (props.leadType) {
    case "General" :
        tmp = "Your request has been submitted!";
        break;
    case "BareMetal" :
        tmp = "Your bare metal demo request has been submitted!";
        break;
    case "ZenlayerBareMetal" :
        tmp = "Your Zenlayer bare metal demo request has been submitted!";
        break;
    case "EnzuBareMetal" :
        tmp = "Your Enzu bare metal demo request has been submitted!";
        break;
    case "HivelocityBareMetal" :
        tmp = "Your Hivelocity bare metal demo request has been submitted!";
        break;
    case "EvocativeBareMetal" :
        tmp = "Your Evocative bare metal demo request has been submitted!";
        break;
    case "Multicloud" :
        tmp = "Your multicloud demo request has been submitted!";
        break;
    case "Colocation" :
        tmp = "Your colocation request has been submitted!";
        break;
    
  }
  return JsxRuntime.jsx(Container.make, {
              className: Cx.cx([
                    css.layout,
                    css.additionalPadding
                  ]),
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(SuccessImage.make, {}),
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: tmp
                          }),
                      JsxRuntime.jsxs("ul", {
                            children: [
                              JsxRuntime.jsx("li", {
                                    children: "You'll be contacted by a representative within 24-hours."
                                  }),
                              JsxRuntime.jsx("li", {
                                    children: "Thank you for using Datacenters.com!"
                                  })
                            ],
                            className: css.nextStepsList
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Button.make, {
                                  size: "LG",
                                  color: "Primary",
                                  expanded: true,
                                  onClick: goBack,
                                  children: "Okay"
                                }),
                            className: css.buttonContainer
                          })
                    ],
                    className: css.messageContainer
                  })
            });
}

var make = LeadConfirmed;

export {
  css ,
  goBack ,
  make ,
}
/* css Not a pure module */
