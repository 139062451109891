// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function Container(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              ref: props.setRef,
              className: className
            });
}

var make = Container;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
